<template lang="pug">
#product-edit
  validation-observer(ref='observer', v-slot='{ passes }')
    b-form(@submit.stop.prevent='passes(onSubmit)')
      b-row
        b-col(cols="12").mt-4
          h5.pb-4.text-secondary Datos del vehículo
        b-col(cols="12", md="6")
          validation-provider(name="Marca" rules="required" v-slot="validationContext")
            b-form-group(label="Marca")
              b-form-select(
                placeholder="Seleccionar"
                v-model="vehicle.vehicles_products.brand_id"
                :options="brands"
                value-field="id"
                text-field="name"
                :state="getValidationState(validationContext)"
                aria-describedby="input-brand"
              )
                b-form-invalid-feedback#input-brand(v-text="validationContext.errors[0]")
        b-col(cols="12", md="6")
          validation-provider(name="Modelo" rules="required" v-slot='validationContext')
            b-form-group(label="Modelo")
              b-form-select(
                placeholder="Seleccionar"
                v-model="vehicle.vehicles_products.modelo_id"
                :options="models"
                value-field="id"
                text-field="name"
                aria-describedby="input-model"
                :disabled="vehicle.vehicles_products.brand_id ? false : true"
                :state="getValidationState(validationContext)"
              )
                b-form-invalid-feedback#input-model(v-text="validationContext.errors[0]")
        b-col(cols="12", md="6")
          validation-provider(name="Versión" rules="required|min:3" v-slot="validationContext")
            b-form-group(label='Versión')
              b-form-input(
                v-model="vehicle.vehicles_products.version"
                type="text"
                aria-describedby="input-version"
                :disabled="vehicle.vehicles_products.modelo_id ? false : true"
                :state="getValidationState(validationContext)"
              )
                b-form-invalid-feedback#input-version(v-text="validationContext.errors[0]")
        b-col(cols="12", md="6")
          validation-provider(name="Carroceria" rules="required" v-slot='validationContext')
            b-form-group(label="Carroceria")
              b-form-select(
                placeholder="Seleccionar"
                v-model="vehicle.vehicles_products.bodywork_id"
                :options="bodyworks"
                value-field="id"
                text-field="name"
                aria-describedby="input-bodywork"
                :disabled="vehicle.vehicles_products.version ? false : true"
                :state="getValidationState(validationContext)"
              )
              b-form-invalid-feedback#input-bodywork(v-text="validationContext.errors[0]")
        b-col(cols="12", :md="(isAdmin||isProfessional)?'3':'2'")
          validation-provider(name="Año" rules="required|min_value:1960|max_value:2024" v-slot="validationContext")
            b-form-group(label="Año")
              b-form-input(
                v-model="vehicle.vehicles_products.year"
                type="number"
                min="1960"
                max="2024"
                step="1"
                :disabled="vehicle.vehicles_products.bodywork_id ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-year"
              )
              b-form-invalid-feedback#input-year {{ validationContext.errors[0] }}
        b-col(cols="12", :md="(isAdmin||isProfessional)?'3':'2'")
          validation-provider(name="Cilindrada" rules="required" v-slot='validationContext')
            b-form-group(label='Cilindrada')
              b-form-input(
                v-model="vehicle.vehicles_products.displacement"
                type="number"
                min="0.1"
                step="0.1"
                :disabled="vehicle.vehicles_products.year ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-displacement"
              )
              b-form-invalid-feedback#input-displacement(v-text="validationContext.errors[0]")
        b-col(cols="12", md="3")
          validation-provider(name="Kilómetraje" rules="required|numeric" v-slot="validationContext")
            b-form-group(label="Kilómetraje")
              b-form-input(
                v-model="vehicle.vehicles_products.mileage"
                type="number"
                min="0"
                step="0.1"
                :disabled="vehicle.vehicles_products.displacement ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-mileage"
              )
              b-form-invalid-feedback#input-mileage(v-text="validationContext.errors[0]")
        b-col(cols="12", :md="(isAdmin||isProfessional)?'3':'2'")
          validation-provider(name="Nº Dueños" rules="required|numeric" v-slot="validationContext")
            b-form-group(label="Nº Dueños")
              b-form-input(
                v-model="vehicle.vehicles_products.owners",
                type="number"
                min="0"
                step="1"
                :disabled="vehicle.vehicles_products.mileage ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-owners"
              )
              b-form-invalid-feedback#input-mileage(v-text="validationContext.errors[0]")
        b-col(cols="12", md="6")
          validation-provider(name="Transmisión" rules="required" v-slot="validationContext")
            b-form-group(label="Transmisión")
              RadioButtonIcon(
                name="transmission"
                v-model="vehicle.vehicles_products.transmission"
                :options="transmissions"
                :disabled="vehicle.vehicles_products.displacement ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-mileage"
              )
              b-form-invalid-feedback#input-mileage(v-text="validationContext.errors[0]")
        b-col(cols="12", md="6")
          validation-provider(name="Tracción" rules="required" v-slot="validationContext")
            b-form-group(label="Tracción")
              RadioButtonIcon(
                name="traction"
                v-model="vehicle.vehicles_products.traction"
                :options="tractions"
                :disabled="vehicle.vehicles_products.transmission ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-traction"
              )
              b-form-invalid-feedback#input-traction {{ validationContext.errors[0] }}
        b-col(cols="12")
          validation-provider(name="Combustible" rules="required" v-slot="validationContext")
            b-form-group(label="Combustible")
              RadioButtonIcon(
                name="fuel"
                v-model="vehicle.vehicles_products.fuel"
                :options="fuels"
                :disabled="vehicle.vehicles_products.traction ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-fuel"
              )
              b-form-invalid-feedback#input-fuel {{ validationContext.errors[0] }}
        b-col(cols="12", md="3")
          validation-provider(name="Nº Asientos" rules="required|numeric" v-slot="validationContext")
            b-form-group(label="Nº Asientos")
              b-form-input(
                v-model="vehicle.vehicles_products.number_seat"
                type="number"
                min="1"
                step="1"
                :disabled="vehicle.vehicles_products.fuel ? false : true"
                :state="getValidationState(validationContext)" aria-describedby="input-seats"
              )
              b-form-invalid-feedback#input-seats {{ validationContext.errors[0] }}
        b-col(cols="12", md="3")
          validation-provider(name="Nº Puertas" rules="required|numeric" v-slot="validationContext")
            b-form-group(label="Nº Puertas")
              b-form-input(
                v-model="vehicle.vehicles_products.number_door"
                type="number"
                min="1"
                step="1"
                :disabled="vehicle.vehicles_products.number_seat ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-doors"
              )
              b-form-invalid-feedback#input-doors {{ validationContext.errors[0] }}
        b-col(cols="12", md="3")
          validation-provider(name="Precio" rules="required" v-slot="validationContext")
            b-form-group(label="Precio")
              b-form-input(
                v-model="vehicle.price"
                v-money="money"
                type="text"
                :disabled="vehicle.vehicles_products.number_door ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="input-price"
              )
              b-form-invalid-feedback#input-price {{ validationContext.errors[0] }}
        b-col(cols="12", md="3")
          b-form-group(label="Precio Con Crédito")
            b-form-input(
              v-model="vehicle.funded_price"
              v-money="money"
              type="text"
              placeholder="Opcional"
              :disabled="vehicle.price ? false : true"
              aria-describedby="input-financed-price"
            )
        b-col(cols="12")
          validation-provider(name="Descripción" rules="required" v-slot="validationContext")
            b-form-group(label="Descripción")
              b-form-textarea(
                v-model="vehicle.description"
                placeholder="Cuéntanos más acerca de tu espacio"
                rows="5"
                :disabled="vehicle.price ? false : true"
                :state="getValidationState(validationContext)"
                aria-describedby="textarea-description"
              )
              b-form-invalid-feedback#textarea-description {{ validationContext.errors[0] }}
        b-col(cols="12")
          b-form-group(id="group-accessories" label="Accesorios")
            b-form-checkbox-group(
              :options="accessories"
              v-model="vehicle.vehicles_products.accessories"
              switches
              size="sm"
              value-field="id"
              text-field="name"
              name="accesorios"
              id="checkbox-accessories"
              :disabled="vehicle.description ? false : true"
            )
        b-col(cols="12")
          h5.mt-3 Video del vehículo:
          b-form-group#group-video.mb-1
            b-form-input(
              type="text"
              name="video"
              v-model="vehicle.video"
              placeholder="Ejemplo: Ci8yyl0JQTQ"
              maxlength="11"
              size="11"
              :disabled="vehicle.vehicles_products.accessories.length > 0 ? false : true"
            )
          small.ml-3.mb-3
            span.text-muted  https://www.youtube.com/watch?v=
            span Ci8yyl0JQTQ
        b-col(cols="12")
          b-overlay#loading(:show="showUpload" opacity="0.7" no-wrap)
            template(v-slot:overlay="")
              .text-center
                b-spinner(variant="info")
                span.d-block.text-info Espere un momento...
          #images-wrapper
            draggable#images(v-model="vehicle.images" group="images" ghost-class="ghost" @end="updateOrder")
              .image-wrapper(v-for="(image, key) in vehicle.images" v-bind:key="`${rndStr(3)}-${key}`")
                label.image(draggable="true")
                  a.image-trash(@click="destroyImage(key, image.id)")
                    font-awesome-icon(icon="times")
                  b-img-lazy(v-bind="mainProps" :alt="`Imagen-${key}`" :src="image.thumbnail")
            input#images-upload(
              type="file"
              ref="file"
              name="files[]"
              multiple=""
              accept="image/png, image/jpeg, image/gif"
              @change="changeImage"
              :disabled="vehicle.vehicles_products.accessories.length > 0 ? false : true"
            )
            .image-upload(@click="$refs.file.click()")
              label.image.placeholder
                i.icon-camera.fa-4x
        b-col(cols="12")
          b-progress(
            v-if="images.length > 0"
            :max="max"
            variant="okcarpremium mb-2"
            height="2rem"
            striped
            :animated="true"
          )
            b-progress-bar(:value="progress" :label="`${((progress / max) * 100).toFixed(0)}%`")
        b-col(cols='12')
          b-button(type="submit", :disabled="butonDisabled", block, variant="outline-okcarpremium") {{ buttonText }}
</template>
<script>
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'
import RadioButtonIcon from '@/components/product/RadioButtonIcon'
import { mapState, mapGetters, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'

export default {
  name: "EditVehicle",
  components: {
    draggable,
    RadioButtonIcon,
    Multiselect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    regions: {
      type: Array,
      default: () => [],
    },
    sellers: {
      type: Array,
      default: () => [],
    },
    bodyworks: {
      type: Array,
      default: () => [],
    },
    brands: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    mask,
    money: VMoney,
  },
  data() {
    return {
      customMask: {
        mask: "AA-FF-FF",
        tokens: {
          A: {
            pattern: /[a-zA-Z]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
          F: {
            pattern: /[0-9a-zA-Z]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
        },
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      vehicle: this.value,
      payment_types: [
        { id: 0, name: "Seleccionar..." },
        { id: 1, name: "Contado" },
        { id: 2, name: "Crédito" },
      ],
      states: [
        { name: "Nuevo", value: "nuevo", icon: "icon-home" },
        { name: "Usado", value: "usado", icon: "" },
      ],
      transmissions: [
        { name: "Automático", value: "automático", icon: "icon-automatic" },
        { name: "Manual", value: "manual", icon: "icon-mechanic" },
      ],
      tractions: [
        { name: "4X2", value: "4x2", icon: "icon-x2" },
        { name: "4X4", value: "4x4", icon: "icon-x4" },
        { name: "AWD", value: "awd", icon: "icon-awd" },
      ],
      fuels: [
        { name: "Bencina", value: "bencina", icon: "icon-gasoline" },
        { name: "Diesel", value: "diesel", icon: "icon-petroleum" },
        { name: "Electrico", value: "electrico", icon: "icon-electric" },
        { name: "Gas", value: "gas", icon: "icon-gas" },
        { name: "Hibrido", value: "hibrido", icon: "icon-hibrido" },
      ],
      role: 0,
      butonDisabled: false,
      buttonText: "Guardar cambios",
      communes: [{ id: null, name: "Seleccionar..." }],
      models: [{ id: null, name: "Seleccionar..." }],
      urlImg: `${this.$axios.defaults.baseURL}`,
      maxUpload: 0,
      maxImage: this.$store.state.image.maxImages,
      show: false,
      showUpload: false,
      image: null,
      images: [],
      imagesBase64: [],
      max: 0,
      step: 0,
      progress: 0,
      mainProps: {
        center: true,
        blankColor: "#bbb",
      },
    };
  },
  mounted() {
    this.getModelSelect();
    this.getCommuneSelect();
    this.pushBtnUpload();
    this.getAccessories();
  },
  computed: {
    ...mapState("auth", ["isAdmin", "isProfessional"]),
    ...mapState("accessory", ["accessories"]),
    ...mapGetters("accessory", ["getAccessoryById"]),
    permissionBrand: function () {
      if (this.isAdmin || this.isProfessional) {
        const takeover_price = parseInt(
          this.vehicle.vehicles_products.takeover_price
            .toString()
            .replace(/\./g, "")
            .trim(),
          10
        );
        return takeover_price > 1 ? false : true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "vehicle.images": function () {
      this.pushBtnUpload();
    },
    "vehicle.region_id": function () {
      this.getCommuneSelect();
      this.vehicle.commune_id = null;
      if (this.communes) this.communes.unshift({ id: null, name: "Seleccionar..." });
    },
    "vehicle.vehicles_products.brand_id": function (id) {
      this.models = [];
      this.$axios
        .get(`/api/v1/models/${id}`)
        .then((res) => {
          this.models = res.data.data;
          this.vehicle.model = null;
          if (this.models) this.models.unshift({ id: null, name: "Seleccionar..." });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    imagesBase64: function () {
      const arrayImages = JSON.parse(JSON.stringify(this.imagesBase64));
      if (this.images.length === arrayImages.length) {
        this.max = 100;
        this.step = 100 / this.images.length;
        arrayImages.forEach((image) => this.uploadImage(image));
      }
    },
  },
  methods: {
    ...mapActions("accessory", ["getAccessories"]),
    updateOrder() {
      this.vehicle.images.forEach((image, index) => {
        image.order = index + 1;
      });
      this.saveOrder(this.vehicle.images)
    },
    saveOrder(images) {
      this.$axios.post("/api/v1/image/order", { images: this.vehicle.images })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async uploadImage(new_image) {
      const _this = this;
      new_image.product_id = this.vehicle.id;
      try {
        const {
          data: { image },
        } = await this.$axios.post("/api/v1/image", new_image);
        this.progress += this.step;
        await this.vehicle.images.push({
          id: image.id,
          image: image.url,
          thumbnail: image.urlThumbnail,
          publicId: image.publicId,
        });
        if (parseInt(this.progress, 10) == parseInt(this.max, 10)) {
          setTimeout(function () {
            _this.showUpload = false;
            _this.images = [];
            _this.imagesBase64 = [];
            _this.max = 0;
            _this.step = 0;
            _this.progress = 0;
          }, 800);
        }
      } catch (e) {
        this.progress += this.step;
        this.$bvToast.toast(e.response.data.message, {
          title: "Error al subir imagen",
          variant: "danger",
          solid: true,
        });
      }
    },
    pushBtnUpload() {
      if (!this.isAdmin && this.vehicle.images.length > this.maxImage) {
        this.vehicle.images = this.vehicle.images.splice(0, this.maxImage);
      }
      this.maxUpload = this.maxImage - this.vehicle.images.length;
    },
    readAndPreview(file) {
      if (!/\.(jpe?g|png|gif|heic|jfif)$/i.test(file.name)) {
        this.$swal({
          title: "Imagen no soportada!",
          text: `Favor de verificar la extension de la imagen: "${file.name}", los formatos soportados son .jpg,j.peg,.png o .gif`,
          icon: "error",
          confirmButtonClass: "btn btn-outline-danger",
          confirmButtonText: "Ok",
          buttonsStyling: false,
        });
        //return false;
      }
      let reader = new FileReader();
      reader.addEventListener("load", this.setImg, false);
      reader.readAsDataURL(file);
    },
    setImg(e) {
      this.imagesBase64.push({
        image: e.target.result,
      });
    },
    rndStr(len) {
      let text = " ";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    isBase64(str) {
      return str.length > 28;
    },
    changeImage(e) {
      this.images = e.target.files;
      console.log(this.image)
      this.showUpload = true;
      if (this.images.length <= this.maxUpload) {
        if (this.images) {
          [].forEach.call(this.images, this.readAndPreview);
        }
        this.$emit("input", this.images);
      } else {
        this.$swal(
          "Maximo de images",
          `No es posible subir mas de ${this.maxUpload} imagenes`,
          "error"
        );
        this.showUpload = false;
        this.images = [];
        this.imagesBase64 = [];
        this.max = 0;
        this.step = 0;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getModelSelect() {
      this.models = [];
      this.$axios
        .get(`/api/v1/models/${this.vehicle.vehicles_products.brand_id}`)
        .then((res) => {
          this.models = res.data.data;
          if (this.models) this.models.unshift({ id: null, name: "Seleccionar..." });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCommuneSelect() {
      this.$axios
        .get(`/api/v1/communes/${this.vehicle.region_id}`)
        .then((res) => {
          this.communes = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSubmit() {
      if (this.$invalid) {
        this.$bvToast.toast("Campos requeridos o Inválidos en el formulario.", {
          title: "Campos requeridos",
          variant: "danger",
          solid: true,
        });
      } else {
        let accessoriesArray = [];
        JSON.stringify(
          //this.vehicle.vehicles_products.accessories.slice().sort((a, b) => a - b)
          this.vehicle.vehicles_products.accessories.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        );
        if (this.vehicle.vehicles_products.accessories.length > 0) {
          this.vehicle.vehicles_products.accessories.forEach((id) =>
            accessoriesArray.push(this.getAccessoryById(id))
          );
        }
        let formData = {
          id: this.vehicle.id,
          seller_id: this.vehicle.seller_id,
          label_name: this.vehicle.label_name,
          label_color: this.vehicle.label_color,
          category_id: this.vehicle.category_id,
          country_id: this.vehicle.country_id,
          region_id: this.vehicle.region_id,
          commune_id: this.vehicle.commune_id,
          sold: this.vehicle.sold,
          price: this.vehicle.price,
          takeover_price: this.vehicle.vehicles_products.takeover_price,
          funded_price: this.vehicle.funded_price,
          description: this.vehicle.description,
          brand_id: this.vehicle.vehicles_products.brand_id,
          modelo_id: this.vehicle.vehicles_products.modelo_id,
          year: this.vehicle.vehicles_products.year,
          version: this.vehicle.vehicles_products.version,
          key_copies: this.vehicle.vehicles_products.key_copies,
          number_seat: this.vehicle.vehicles_products.number_seat,
          number_door: this.vehicle.vehicles_products.number_door,
          displacement: this.vehicle.vehicles_products.displacement,
          bodywork_id: this.vehicle.vehicles_products.bodywork_id,
          mileage: this.vehicle.vehicles_products.mileage,
          fuel: this.vehicle.vehicles_products.fuel,
          transmission: this.vehicle.vehicles_products.transmission,
          traction: this.vehicle.vehicles_products.traction,
          owners: this.vehicle.vehicles_products.owners,
          payment_type: this.vehicle.vehicles_products.payment_type,
          accessories: JSON.stringify(accessoriesArray),
          video: this.vehicle.video,
          images: this.images,
        };
        this.getSeller(this.vehicle.seller_id);
        this.butonDisabled = true;
        this.buttonText = "Cargando ...";
        this.$axios
          .put(`/api/v1/product/${formData.id}`, formData)
          .then((response) => {
            this.$swal({
              title: "Actualización de producto!",
              text: "Producto actualizado con éxito",
              icon: "success",
              confirmButtonClass: "btn btn-outline-primary",
              confirmButtonText: "Ok",
              buttonsStyling: false,
            });
            this.$bvModal.hide("modal-edit-vehicle");
            this.butonDisabled = false;
            this.buttonText = "Guardar cambios";
          })
          .catch((error) => {
            this.butonDisabled = false;
            this.buttonText = "Guardar cambios";
            this.$swal({
              title: "Actualización de producto!",
              text: "Error al realizar la actualización del producto.",
              icon: "error",
              confirmButtonClass: "btn btn-outline-danger",
              confirmButtonText: "Ok",
              buttonsStyling: false,
            });
            console.error(error);
          });
      }
    },
    destroyImage(key, id) {
      this.$swal({
        title: "Eliminar Imagen",
        text: "Estas seguro(a) de eliminar esta imagen",
        icon: "warning",
        confirmButtonClass: "btn btn-outline-danger",
        cancelButtonClass: "btn btn-outline-secondary ml-2",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          if (id === 0) {
            this.$delete(this.vehicle.images, key);
          } else {
            this.$axios
              .delete(`api/v1/image/${id}`)
              .then((res) => {
                this.$delete(this.vehicle.images, key);
                this.$swal({
                  title: "Eliminación de imagen!",
                  text: "Imagen eliminada con éxito",
                  icon: "success",
                  confirmButtonClass: "btn btn-outline-primary btn-lg px-5",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      });
    },
    loading() {
      this.show = true;
      const height = window.outerHeight;
      window.scrollTo({ top: height / 2 });
    },
    ShowCreatedSeller() {
      this.$bvModal.show("modal-created-seller");
    },
    async getSeller(idSeller) {
      if (idSeller) {
        try {
          const { data } = await this.$axios.get(`/api/v1/seller/${idSeller}`);
          const { id, full_name, phone_1, phone_2, email } = data.data;
          const seller = {
            id: id,
            full_name: full_name,
            phone_1: phone_1,
            phone_2: phone_2,
            email: email,
          };
          this.vehicle.seller = seller;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables";
@import "@/assets/scss/upload-images";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.custom-select {
  font-size: 0.85rem;
  border-radius: 20px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}
.multiselect {
  &__tags {
    border-radius: 20px !important;
  }
  &__content-wrapper {
    border-radius: 20px;
    margin: 3px auto;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 8px;
  }
}
#checkbox-accessories {
  display: grid;
  grid-template-columns: 1fr;
  label {
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) {
  .payment_type {
    legend {
      width: 115px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
